import React, { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import LogInButton from '@utility/niveus-google-sso-login';
import { useUser } from '../../provider/User/user';
import { googleAuth } from '../../service/Auth/GoogleAuth';
import { CircularProgress, Box, Typography, Container, Alert } from '@mui/joy';
import { SessionExpiredContext } from '../../provider/User/SessionExpiredContext';
import { styled } from '@mui/system';

const NonSelectableContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  '-webkit-user-select': 'none', /* Safari */
  '-moz-user-select': 'none', /* Firefox */
  '-ms-user-select': 'none', /* Internet Explorer/Edge */
  'user-select': 'none', /* Non-prefixed version, supported by Chrome, Edge, Opera, and Firefox */
});

const LoginPage = ({ client_id }) => {
  const navigate = useNavigate();
  const { user, setUser, checkingAuth } = useUser();
  const { setSessionExpired } = useContext(SessionExpiredContext);
  const [error, setError] = React.useState(null);

  const handleGoogleLogin = async (googleData) => {
    try {
      const data = await googleAuth(googleData);
      if (data && data.success && data.response.email) {
        setUser({ ...data.response, token: googleData.credential });
        localStorage.setItem(
          "email_template_generator",
          JSON.stringify({ ...data.response, token: googleData.credential })
        );
        setSessionExpired(false);
      } else {
        console.error("Login failed:", data.message);
        setError(data.message || "Login failed.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("Error during login. Please try again.");
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <NonSelectableContainer>
      {checkingAuth ? (
        <CircularProgress color="neutral" size="md" />
      ) : (
        <>
          <Box component="img" src="niveus-logo.png" alt="Logo" sx={{ mb: 2 }} />
          <Typography variant="h1" gutterBottom>
            Welcome to Email Template Creator
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <Box mt={2}>
            <LogInButton 
              onGoogleLogin={handleGoogleLogin}
              clientId={client_id}
              loginMethod={"jwt"}
            />
          </Box>
        </>
      )}
    </NonSelectableContainer>
  );
};

export default LoginPage;
