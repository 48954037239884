export const extractCssAndHtml = (content) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  let css = '';

  // Extract inline styles and build CSS string
  doc.querySelectorAll('[style]').forEach((element) => {
    const styles = element.getAttribute('style');
    const tagName = element.tagName.toLowerCase();
    const className = element.getAttribute('class');
    
    if (className) {
      // Append styles to the CSS string with the class name
      css += `.${className} { ${styles} }\n`;
    } else {
      // Append styles to the CSS string with the tag name directly
      css += `${tagName} { ${styles} }\n`;
    }

    // Remove the inline style from the element
    element.removeAttribute('style');
  });

  // Remove 'cid:' prefix from image names
  const htmlContent = doc.body.innerHTML;
  const htmlWithoutCid = htmlContent.replace(/cid:/g, '');

  return { css, html: htmlWithoutCid };
};
