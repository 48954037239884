import { CircularProgress, Container, Typography, Paper, Box, Divider, IconButton, Tooltip, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useParams } from 'react-router-dom';
import { fetchTemplate } from '../../service/Email/EmailTemplate'; // Ensure this import is correct
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { GET_EMAIL_CONTENT } from '../../common/constants/routePath';
import { toast } from 'react-toastify'; // Add toast import for error messages
import { SESSION_EXPIRED } from '../../common/constants/string';
import SessionExpired from '../pages/SessionExpired';

const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

const ContentContainer = styled(Paper)({
  padding: '20px',
  marginTop: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  overflow: 'auto',
});

const StyledPaper = styled(Paper)({
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  height: '100%',
});

const SampleRequestHeading = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ParametersList = styled('ul')({
  padding: 0,
  margin: 0,
  listStyle: 'none',
});

const ParameterItem = styled('li')({
  display: 'flex',
  alignItems: 'center',
  '&::before': {
    content: '"•"',
    marginRight: '8px',
  },
});

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    // Optionally show a toast or notification here
  }).catch(err => {
    console.error('Failed to copy text: ', err);
  });
};

export const PreviewTemplate = () => {
  const { template_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [emailContent, setEmailContent] = useState('');
  const [tempName, setTempName] = useState('');
  const [tempParameters, setTempParameters] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    const loadTemplate = async () => {
      try {
        const response = await fetchTemplate(template_id);
        if (response.success) {
          let { emailContent, attachments, parameters, templateName } = response.data;
          setTempName(templateName);

          // Replace cid:imageName with the corresponding base64 data
          attachments.forEach(attachment => {
            const cidPlaceholder = `cid:${attachment.name}`;
            emailContent = emailContent.replace(new RegExp(cidPlaceholder, 'g'), attachment.base64Url);
          });

          setEmailContent(emailContent);
          setTempParameters(parameters);
        } else if (response.message === SESSION_EXPIRED) {
          toast.error(SESSION_EXPIRED);
          setSessionExpired(true);
        } else {
          console.error('Failed to fetch template:', response.message);
        }
      } catch (error) {
        console.error('Failed to fetch template:', error);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      loadTemplate();
    }
  }, [loading, template_id]);

  const sampleRequestBody = JSON.stringify({
    template_id: template_id,
    parameters: tempParameters.reduce((acc, param) => {
      acc[param] = `<${param}>`; // Placeholder values for parameters
      return acc;
    }, {}),
  }, null, 2);

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <CircularProgress color="primary" />
        </LoadingContainer>
      ) : (
        <>
          {emailContent && (
            <>
              <Typography variant="h5" gutterBottom align="left">
                {tempName}
              </Typography>
              <ContentContainer>
                <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: emailContent }} />
              </ContentContainer>
              <Grid container spacing={2} style={{ marginTop: '20px', display: 'flex' }}>
                <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <StyledPaper>
                    <Typography variant="h6" align="left">Template Parameters</Typography>
                    <Divider />
                    <Box mt={2}>
                      {tempParameters?.length > 0 ? (
                        <ParametersList>
                          {tempParameters?.map((param, index) => (
                            <ParameterItem key={index}>
                              <Typography variant="body2">{param}</Typography>
                            </ParameterItem>
                          ))}
                        </ParametersList>
                      ) : (
                        <Typography variant="body2">No parameters available.</Typography>
                      )}
                    </Box>
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <StyledPaper>
                    <SampleRequestHeading>
                      <Typography variant="h6">Sample Request</Typography>
                      <Box>
                        <Tooltip title="Copy endpoint">
                          <IconButton
                            size="small"
                            onClick={() => copyToClipboard(GET_EMAIL_CONTENT)}
                          >
                            <ContentCopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </SampleRequestHeading>
                    <Typography variant="body2">{GET_EMAIL_CONTENT}</Typography>
                    <Divider />
                    <Box mt={2} display="flex" justifyContent="space-between">
                      <Typography variant="body2">Request Body:</Typography>
                      <Tooltip title="Copy request body">
                        <IconButton
                          size="small"
                          onClick={() => copyToClipboard(sampleRequestBody)}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box mt={2}>
                      <pre style={{ textAlign: 'left', overflowX: 'auto' }}>
                        <code>
                          {sampleRequestBody}
                        </code>
                      </pre>
                    </Box>
                  </StyledPaper>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      {sessionExpired && <SessionExpired />}
    </Container>
  );
};

export default PreviewTemplate;
