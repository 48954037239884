import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Paper, Box } from '@mui/material';
import { styled } from '@mui/system';

const SuggestionsContainer = styled(Paper)({
  position: 'absolute',
  zIndex: 1,
  marginTop: '8px',
  left: 0,
  right: 0,
  maxHeight: 150,
  overflowY: 'auto',
});

const EmailInput = ({ email, setEmail, allUsers, handleAddEmail }) => {
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (email) {
      const filtered = allUsers
        .filter((user) => user.emailId.toLowerCase().includes(email.toLowerCase()));
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  }, [email, allUsers]);

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        label="Add email Ids"
        value={email}
        autoComplete="off"
        onChange={(e) => setEmail(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleAddEmail(email);
          }
        }}
      />
      {filteredUsers.length > 0 && (
        <SuggestionsContainer>
          {filteredUsers.map((user) => (
            <MenuItem key={user.emailId} onClick={() => handleAddEmail(user.emailId)}>
              {user.emailId}
            </MenuItem>
          ))}
        </SuggestionsContainer>
      )}
    </Box>
  );
};

export default EmailInput;
