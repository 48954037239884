import React, { useContext, useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
  FormControl,
  Chip,
  IconButton,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  shareTemplate,
  fetchUsersEmail,
  revokeAccess,
  updateAccess,
} from "../../service/Email/EmailTemplate";
import { SESSION_EXPIRED } from "../../common/constants/string";
import { toast } from "react-toastify";
import { SessionExpiredContext } from "../../provider/User/SessionExpiredContext";
import { useUser } from "../../provider/User/user";
import EmailInput from "./EmailInput";
import UserMenu from "./UserMenu";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  minHeight: 250,
  backgroundColor: "white",
  borderRadius: 5,
  boxShadow: 24,
  padding: 10,
});

const EmailContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: 16,
  maxHeight: 200,
  overflowY: "auto",
});

const EmailChip = styled(Chip)({
  marginBottom: 8,
  justifyContent: "space-between",
});

const FormControlStyled = styled(FormControl)({
  minWidth: 120,
  marginTop: 7,
  marginLeft: 16,
});

const ShareButton = styled(Button)({
  width: 200,
});

const ShareButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: 2,
});

const InputRow = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const ShareModal = ({
  open,
  handleClose,
  template,
  templateUsers,
  setTemplateUsers,
}) => {
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState("");
  const [access, setAccess] = useState("2");
  const [allUsers, setAllUsers] = useState([]);
  const { setSessionExpired } = useContext(SessionExpiredContext);
  const { user: currentUser } = useUser(); // Get the current logged-in user

  useEffect(() => {
    if (open) {
      const fetchUserEmails = async () => {
        try {
          const response = await fetchUsersEmail();
          if (response.message === SESSION_EXPIRED) {
            toast.error(SESSION_EXPIRED);
            setSessionExpired(true);
          } else {
            setAllUsers(response.data);
          }
        } catch (error) {
          console.error("Failed to fetch user emails:", error);
        }
      };

      fetchUserEmails();
    }
  }, [open, setSessionExpired]);

  const handleAddEmail = (email) => {
    if (email && !emails.includes(email)) {
      const userAlreadyExists = templateUsers.some(
        (user) => user.emailId === email
      );
      if (userAlreadyExists) {
        toast.warn("User already has access");
      } else {
        setEmails([...emails, email]);
        setEmail("");
      }
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmails(emails.filter((e) => e !== emailToRemove));
  };

  const handleShare = async () => {
    const emailList = emails.map((email) => {
      const user = allUsers.find((suggestion) => suggestion.emailId === email);
      return {
        email,
        name: user ? user.fullName : "",
      };
    });

    try {
      const response = await shareTemplate(
        emailList,
        template.templateId,
        access
      );

      if (response.message === SESSION_EXPIRED) {
        toast.error(SESSION_EXPIRED);
        setSessionExpired(true);
      } else {
        toast.success("Template shared successfully");
        // Update templateUsers with new shared users
        const newUsers = emailList.map((emailItem) => ({
          emailId: emailItem.email,
          roleId: access,
        }));
        setTemplateUsers((prevUsers) => [...prevUsers, ...newUsers]);
        setEmails([]); // Clear the emails after sharing
      }
    } catch (error) {
      console.error("Failed to share template:", error);
      toast.error("Failed to share template");
    } finally {
      handleClose();
    }
  };

  const handleChangeRole = async (email, newRole, closeMenu) => {
    try {
      const response = await updateAccess(template.templateId, email, newRole);

      if (response.message === SESSION_EXPIRED) {
        toast.error(SESSION_EXPIRED);
        setSessionExpired(true);
      } else if (response.success) {
        toast.success("Access Updated Successfully");
        // Update the role in the templateUsers state
        setTemplateUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.emailId === email ? { ...user, roleId: newRole } : user
          )
        );
        if (closeMenu) closeMenu(); 
      }
    } catch (error) {
      console.error("Failed to Update Access:", error);
      toast.error("Failed to Update Access");
    }
  };

  const handleRevokeAccess = async (email) => {
    try {
      const response = await revokeAccess(template.templateId, email);

      if (response.message === SESSION_EXPIRED) {
        toast.error(SESSION_EXPIRED);
        setSessionExpired(true);
      } else {
        toast.success("Access Revoked successfully");
        // Remove the user from templateUsers
        setTemplateUsers((prevUsers) =>
          prevUsers.filter((user) => user.emailId !== email)
        );
      }
    } catch (error) {
      console.error("Failed to Revoke Access:", error);
      toast.error("Failed to Revoke Access");
    }
  };

  const getRoleName = (roleId) => {
    switch (roleId) {
      case "1":
        return "Owner";
      case "2":
        return "Editor";
      case "3":
        return "Viewer";
      default:
        return "";
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="share-modal-title"
      aria-describedby="share-modal-description"
    >
      <ModalBox>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
          <IconButton onClick={handleClose} aria-label="close">
            <ArrowBackIcon />
          </IconButton>
          <Typography
            id="share-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginLeft: 1 }}
          >
            Share "{template.templateName}"
          </Typography>
        </Box>
        <InputRow>
          <EmailInput
            email={email}
            setEmail={setEmail}
            allUsers={allUsers}
            handleAddEmail={handleAddEmail}
          />
          <FormControlStyled>
            <Select
              labelId="access-label"
              value={access}
              onChange={(e) => setAccess(e.target.value)}
            >
              {template.role === "1" && <MenuItem value="1">Owner</MenuItem>}
              <MenuItem value="2">Editor</MenuItem>
              <MenuItem value="3">Viewer</MenuItem>
            </Select>
          </FormControlStyled>
        </InputRow>
        {emails.length === 0 && (
          <EmailContainer>
            <List>
              {templateUsers.map((user) => (
                <ListItem
                  key={user.emailId}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ListItemText
                    primary={user.emailId}
                    secondary={getRoleName(user.roleId)}
                  />
                  {template.role === "1" && (
                    <UserMenu
                      user={user}
                      onChangeRole={handleChangeRole}
                      onRevokeAccess={() => handleRevokeAccess(user.emailId)}
                      currentUser={currentUser}
                    />
                  )}
                </ListItem>
              ))}
            </List>
          </EmailContainer>
        )}
        <EmailContainer>
          {emails.map((e) => (
            <EmailChip
              key={e}
              label={e}
              onDelete={() => handleRemoveEmail(e)}
            />
          ))}
        </EmailContainer>
        <ShareButtonContainer>
          <ShareButton
            variant="contained"
            color="primary"
            onClick={handleShare}
            disabled={emails.length === 0}
          >
            Share
          </ShareButton>
        </ShareButtonContainer>
      </ModalBox>
    </Modal>
  );
};

export default ShareModal;
