import React from 'react';
import Editor from '@monaco-editor/react';

const CssTab = ({ cssCode, handleCssChange }) => (
  <Editor
    height="70vh"
    width="100vh"
    language="css"
    value={cssCode}
    onChange={handleCssChange}
    options={{
      selectOnLineNumbers: true,
      automaticLayout: true,
      autoClosingBrackets: 'always',
    }}
  />
);

export default CssTab;
