import React from 'react';
import CSSOM from 'cssom'; // Ensure to install the cssom library

const PreviewTab = ({ htmlCode, cssCode, uploadedImages }) => {
  console.log(uploadedImages,"ugswgsyhw")
  const applyInlineStyles = (html, css) => {
    const div = document.createElement('div');
    div.innerHTML = html.trim();

    const styleSheet = CSSOM.parse(css);
    styleSheet.cssRules.forEach((rule) => {
      if (rule.selectorText) {
        const elements = div.querySelectorAll(rule.selectorText);
        elements.forEach((element) => {
          Array.from(rule.style).forEach((property) => {
            element.style[property] = rule.style[property];
          });
        });
      }
    });

    return div.innerHTML;
  };

  const renderHtmlWithBase64 = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html.trim();

    uploadedImages.forEach(({ name, base64Url }) => {
      div.innerHTML = div.innerHTML.replace(new RegExp(name, 'g'), base64Url);
    });

    return div.innerHTML;
  };

  const htmlWithInlineStyles = applyInlineStyles(htmlCode, cssCode);
  const finalHtml = renderHtmlWithBase64(htmlWithInlineStyles);

  return (
    <div className='preview-tab'>
      <div dangerouslySetInnerHTML={{ __html: finalHtml }} />
    </div>
  );
};

export default PreviewTab;
