import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const TemplateCardWrapper = styled(Card)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "300px",
  minWidth: "300px",
  maxWidth: "250px",
  borderRadius: "15px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  position: "relative",
});

const TemplateCardContent = styled(CardContent)({
  flex: "1 0 auto",
  padding: "20px",
  textAlign: "center",
});

const TemplateName = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1.25rem",
  marginBottom: "10px",
});

const TemplateIdContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "10px",
});

const ActionsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-evenly",
  padding: "10px",
  marginBottom: "10px",
});

const CopyIcon = styled(ContentCopyIcon)({
  cursor: "pointer",
  marginLeft: "5px",
});

const TemplateCard = ({
  template,
  handleEdit,
  handlePreview,
  copyToClipboard,
  handleMoreClick,
}) => {
  return (
    <TemplateCardWrapper>
      <CardMedia
        component="img"
        height="140"
        image="https://t4.ftcdn.net/jpg/01/52/54/19/240_F_152541972_qB23pQVp50qTooCYbJ0KX3NdVmQGdnUJ.jpg"
        alt="Simple placeholder image"
      />
      <TemplateCardContent>
        <TemplateName variant="body2" sx={{ fontSize: "1rem" }}>
          {template.templateName}
        </TemplateName>
        <TemplateIdContainer>
          <Typography variant="body2" sx={{ fontSize: "0.70rem" }}>
            {template.templateId}
          </Typography>
          <Tooltip title="Copy to clipboard">
            <IconButton
              size="small"
              onClick={() => copyToClipboard(template.templateId)}
            >
              <CopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </TemplateIdContainer>
      </TemplateCardContent>
      <ActionsContainer>
        {(template.role === "1" || template.role === "2") && (
          <Tooltip title="Edit">
            <IconButton size="small" onClick={() => handleEdit(template)}>
              <EditIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Preview">
          <IconButton size="small" onClick={() => handlePreview(template)}>
            <VisibilityIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </ActionsContainer>
      {(template.role === "1" || template.role === "2") && (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => handleMoreClick(event, template)}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "black",
            backgroundColor: "#ffff",
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )}
    </TemplateCardWrapper>
  );
};

export default TemplateCard;
