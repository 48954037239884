// defaultCode.js
export const defaultHtmlCode = `
<div class="container">
  <div class="header">
    <h1 class="title">Welcome to Email Template Creator </h1>
  </div>
  <div>
    <p>Sample paragraph</p>
  </div>
</div>
`;

export const defaultCssCode = `
.title { color: blue; font-size: 2em; }
.container { padding:20px }
.header { justify-content: center; }
`;
