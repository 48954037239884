// ToolsPanel.js
import React from 'react';
import TextInputList from './TextInputList';
import ImageUpload from './ImageUpload';

const ToolsPanel = ({ variables, setVariables, uploadedImages, handleImageUpload, handleRemoveImage,copyToClipboard }) => (
  <div className='tools-panel'>
    <TextInputList variables={variables} setVariables={setVariables} />
    <ImageUpload
      uploadedImages={uploadedImages}
      handleImageUpload={handleImageUpload}
      handleRemoveImage={handleRemoveImage}
      copyToClipboard={copyToClipboard}
    />
  </div>
);

export default ToolsPanel;
