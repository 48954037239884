import React from "react";

export const LogoIcon = (props) => {
  return (
    <>
      {props.mainLogo ? (
        <svg
          width="149"
          height="34"
          viewBox="0 0 149 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_25_167)">
            <path
              d="M28.7783 30.5986V4.41755C28.7783 3.85812 29.2483 3.40198 29.8277 3.40198H32.7687C33.3451 3.40198 33.8151 3.85812 33.8151 4.41755V30.5986H28.7813H28.7783Z"
              fill="white"
            />
            <path
              d="M0 30.5928V15.0581C0 8.06384 5.03088 3.40198 11.7259 3.40198C18.4209 3.40198 23.4429 8.06384 23.4429 15.0552V30.5899H18.4121V15.0552C18.4121 10.947 15.3261 8.06384 11.7259 8.06384C8.12567 8.06384 5.03088 10.947 5.03088 15.0552V30.5899H0V30.5928Z"
              fill="white"
            />
            <path
              d="M64.5266 3.40198C64.9286 3.40198 65.2064 3.79214 65.0616 4.15648L55.7506 27.8789C54.8343 30.2629 53.6342 30.5986 51.8578 30.5986C50.0813 30.5986 48.8871 30.2629 47.9738 27.8789L38.716 4.15648C38.5741 3.79214 38.852 3.40198 39.254 3.40198H43.5163C43.7558 3.40198 43.9686 3.54542 44.0543 3.76345L51.9198 24.0461L59.7322 3.76345C59.815 3.54542 60.0278 3.40198 60.2672 3.40198H64.5236H64.5266Z"
              fill="white"
            />
            <path
              d="M51.698 23.7048C51.698 23.7048 51.831 23.8654 52.0409 23.6617V23.9601L51.8694 24.0806L51.698 23.9687V23.7048Z"
              fill="#7C7B7B"
            />
            <path
              d="M125.11 26.9666C125.11 26.3441 125.63 25.8391 126.272 25.8391H139.478C141.87 25.8391 142.804 24.9326 142.804 23.2285C142.804 18.2396 125.113 20.1732 125.113 10.7663C125.11 5.9495 128.556 3.40198 134.684 3.40198H145.612C146.253 3.40198 146.773 3.90689 146.773 4.52943V7.03392C146.773 7.65646 146.253 8.16138 145.612 8.16138H133.812C131.297 8.16138 130.543 8.84416 130.481 10.5425C130.416 15.2474 148.172 13.2593 148.172 23.0076C148.172 28.16 144.435 30.6014 139.472 30.6014H126.266C125.624 30.6014 125.104 30.0965 125.104 29.474V26.9666H125.11Z"
              fill="white"
            />
            <path
              d="M93.7718 18.277L91.484 17.97C90.9076 17.8925 90.3667 18.2626 90.2543 18.8163C89.3528 23.197 85.2589 26.456 80.4261 26.2093C75.5312 25.9597 71.5881 22.0351 71.4432 17.2786C71.2836 12.0602 75.5962 7.77989 80.9404 7.77989C83.4884 7.77989 85.8028 8.75529 87.5084 10.3418L78.0082 15.5114C77.7865 15.6319 77.7067 15.9073 77.8309 16.1225L79.5275 19.0573C79.6517 19.2724 79.9354 19.3499 80.1571 19.2294L93.4585 11.9942C93.6802 11.8737 93.8546 11.5668 93.6713 11.2196C93.2457 10.2729 92.0456 8.64628 91.8387 8.45694C89.14 5.22089 84.9427 3.20697 80.2694 3.41639C73.0601 3.74057 67.2223 9.44955 66.9385 16.4495C66.6222 24.2097 73.0128 30.5986 80.9375 30.5986C87.7773 30.5986 93.4733 25.8392 94.7029 19.5479C94.8212 18.9425 94.4014 18.3602 93.7718 18.277Z"
              fill="white"
            />
            <path
              d="M117.8 13.6035C117.383 13.6035 117.043 13.9334 117.043 14.3379V18.6211C117.043 22.8211 113.892 25.7846 110.203 25.8075C110.203 25.8075 110.177 25.8075 110.165 25.8075C106.458 25.8075 103.287 22.8354 103.287 18.6211V14.4154C103.287 13.9679 102.911 13.6035 102.45 13.6035H98.9534C98.4923 13.6035 98.1169 13.9679 98.1169 14.4154V18.6211C98.1169 25.8075 103.287 30.6014 110.165 30.6014C110.171 30.6014 110.177 30.6014 110.183 30.6014C117.052 30.5928 122.216 25.8018 122.216 18.624V14.3408C122.216 13.9363 121.876 13.6064 121.459 13.6064H117.8V13.6035Z"
              fill="#F7B526"
            />
            <path
              d="M100.827 8.68344C102.324 8.68344 103.538 7.50563 103.538 6.05272C103.538 4.59981 102.324 3.422 100.827 3.422C99.3305 3.422 98.1169 4.59981 98.1169 6.05272C98.1169 7.50563 99.3305 8.68344 100.827 8.68344Z"
              fill="#EA4535"
            />
            <path
              d="M119.503 8.68344C121 8.68344 122.213 7.50563 122.213 6.05272C122.213 4.59981 121 3.422 119.503 3.422C118.006 3.422 116.792 4.59981 116.792 6.05272C116.792 7.50563 118.006 8.68344 119.503 8.68344Z"
              fill="#EA4535"
            />
          </g>
          <defs>
            <clipPath id="clip0_25_167">
              <rect width="148.24" height="34" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 149 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#clip0_2006_2)">
            <path
              d="M28.7783 30.5985V4.41753C28.7783 3.85811 29.2482 3.40196 29.8276 3.40196H32.7687C33.3451 3.40196 33.8151 3.85811 33.8151 4.41753V30.5985H28.7812H28.7783Z"
              fill="#010101"
            />
            <path
              d="M0 30.5928V15.058C0 8.0638 5.03088 3.40194 11.7259 3.40194C18.4209 3.40194 23.4429 8.0638 23.4429 15.0552V30.5899H18.4121V15.0552C18.4121 10.947 15.3261 8.0638 11.7259 8.0638C8.12567 8.0638 5.03088 10.947 5.03088 15.0552V30.5899H0V30.5928Z"
              fill="#010101"
            />
            <path
              d="M64.5265 3.40196C64.9285 3.40196 65.2063 3.79213 65.0615 4.15647L55.7505 27.8789C54.8342 30.2629 53.6341 30.5985 51.8577 30.5985C50.0812 30.5985 48.887 30.2629 47.9737 27.8789L38.7159 4.15647C38.574 3.79213 38.8519 3.40196 39.2539 3.40196H43.5162C43.7556 3.40196 43.9685 3.54541 44.0542 3.76344L51.9197 24.0461L59.7321 3.76344C59.8149 3.54541 60.0277 3.40196 60.2671 3.40196H64.5235H64.5265Z"
              fill="#010101"
            />
            <path
              d="M51.698 23.7047C51.698 23.7047 51.831 23.8654 52.0409 23.6617V23.96L51.8695 24.0805L51.698 23.9686V23.7047Z"
              fill="#7C7B7B"
            />
            <path
              d="M125.11 26.9666C125.11 26.3441 125.63 25.8391 126.271 25.8391H139.478C141.87 25.8391 142.804 24.9326 142.804 23.2285C142.804 18.2396 125.113 20.1732 125.113 10.7663C125.11 5.94949 128.556 3.40196 134.684 3.40196H145.612C146.253 3.40196 146.773 3.90688 146.773 4.52942V7.03391C146.773 7.65645 146.253 8.16136 145.612 8.16136H133.812C131.296 8.16136 130.543 8.84415 130.481 10.5425C130.416 15.2474 148.171 13.2593 148.171 23.0076C148.171 28.16 144.435 30.6014 139.472 30.6014H126.266C125.624 30.6014 125.104 30.0965 125.104 29.474V26.9666H125.11Z"
              fill="#010101"
            />
            <path
              d="M93.7718 18.2769L91.484 17.9699C90.9076 17.8924 90.3666 18.2625 90.2543 18.8162C89.3528 23.1969 85.2589 26.4559 80.4261 26.2092C75.5312 25.9596 71.588 22.035 71.4432 17.2785C71.2836 12.0601 75.5962 7.77979 80.9404 7.77979C83.4884 7.77979 85.8028 8.7552 87.5083 10.3417L78.0082 15.5113C77.7865 15.6318 77.7067 15.9072 77.8308 16.1224L79.5275 19.0572C79.6516 19.2724 79.9354 19.3498 80.1571 19.2293L93.4585 11.9941C93.6802 11.8736 93.8546 11.5667 93.6713 11.2195C93.2457 10.2728 92.0456 8.64618 91.8387 8.45684C89.14 5.22079 84.9426 3.20687 80.2694 3.41629C73.0601 3.74047 67.2222 9.44946 66.9385 16.4494C66.6222 24.2096 73.0128 30.5985 80.9374 30.5985C87.7773 30.5985 93.4733 25.8391 94.7029 19.5478C94.8211 18.9424 94.4014 18.3601 93.7718 18.2769Z"
              fill="#010101"
            />
            <path
              d="M117.8 13.6035C117.383 13.6035 117.043 13.9334 117.043 14.3379V18.6211C117.043 22.821 113.892 25.7846 110.203 25.8075C110.203 25.8075 110.177 25.8075 110.165 25.8075C106.458 25.8075 103.287 22.8354 103.287 18.6211V14.4154C103.287 13.9678 102.911 13.6035 102.45 13.6035H98.9534C98.4923 13.6035 98.1169 13.9678 98.1169 14.4154V18.6211C98.1169 25.8075 103.287 30.6013 110.165 30.6013C110.171 30.6013 110.177 30.6013 110.183 30.6013C117.052 30.5927 122.216 25.8018 122.216 18.6239V14.3408C122.216 13.9363 121.876 13.6063 121.459 13.6063H117.8V13.6035Z"
              fill="#F7B526"
            />
            <path
              d="M100.827 8.68344C102.324 8.68344 103.538 7.50563 103.538 6.05272C103.538 4.59981 102.324 3.422 100.827 3.422C99.3304 3.422 98.1169 4.59981 98.1169 6.05272C98.1169 7.50563 99.3304 8.68344 100.827 8.68344Z"
              fill="#EA4535"
            />
            <path
              d="M119.503 8.68338C121 8.68338 122.213 7.50557 122.213 6.05266C122.213 4.59975 121 3.42194 119.503 3.42194C118.006 3.42194 116.792 4.59975 116.792 6.05266C116.792 7.50557 118.006 8.68338 119.503 8.68338Z"
              fill="#EA4535"
            />
          </g>
          <defs>
            <clipPath id="clip0_2006_2">
              <rect width={148.24} height={34} fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};
