import "./Header.css";
import { LogoIcon } from "./LogoIcon";
import { User } from "./User";

import React from "react";

export const HeaderNav = ({ user }) => {

  return (
    <nav className="nav-class">
      <LogoIcon className="icon"/>
      <User user={user} />
    </nav>
  );
};
