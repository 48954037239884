import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

const UserMenu = ({ user, onChangeRole, onRevokeAccess, currentUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [confirmRevokeOpen, setConfirmRevokeOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmRevokeClose = () => {
    setConfirmRevokeOpen(false);
  };

  const handleConfirmRevokeOpen = () => {
    setConfirmRevokeOpen(true);
    handleClose();
  };

  const handleRevokeAccess = () => {
    onRevokeAccess(user.emailId);
    handleConfirmRevokeClose();
  };

  const isCurrentUser = user.emailId === currentUser.email;

  return (
    <div>
      <Button onClick={handleClick} disabled={isCurrentUser}>
        {user.role} <MoreVertIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          disabled={isCurrentUser}
          onClick={() => onChangeRole(user.emailId, "3", handleClose)}
        >
          Viewer
        </MenuItem>
        <MenuItem
          disabled={isCurrentUser}
          onClick={() => onChangeRole(user.emailId, "2", handleClose)}
        >
          Editor
        </MenuItem>
        <MenuItem
          disabled={isCurrentUser}
          onClick={() => onChangeRole(user.emailId, "1", handleClose)}
        >
          Owner
        </MenuItem>
        <MenuItem
          disabled={isCurrentUser}
          onClick={handleConfirmRevokeOpen}
        >
          Revoke Access
        </MenuItem>
      </Menu>
      <ConfirmDeleteDialog
        open={confirmRevokeOpen}
        onClose={handleConfirmRevokeClose}
        onConfirm={handleRevokeAccess}
        message={"Are you sure you want to revoke access?"}
      />
    </div>
  );
};

export default UserMenu;
