import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import Tooltip from '@mui/material/Tooltip';

const ImageUpload = ({
  uploadedImages,
  handleImageUpload,
  handleRemoveImage,
  copyToClipboard
}) => (
  <div className="upload-container">
    <p>Upload Image</p>
    <input type="file" accept="image/*" onChange={handleImageUpload} />
    {uploadedImages.length > 0 && (
      <div>
        <p>Images:</p>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {uploadedImages.map(({ name }, index) => (
            <li key={index} className="list-class">
              <Tooltip 
                title="Click to copy" 
                placement="bottom-end"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ],
                }}
              >
                <input
                  type="text"
                  value={name}
                  readOnly
                  className="list-input"
                  onClick={() => copyToClipboard(name)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
              <button
                onClick={() => handleRemoveImage(index)}
                className="close-icon"
              >
                <CloseCircleOutlined />
              </button>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

export default ImageUpload;
