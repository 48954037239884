import axios from "axios";
import { GOOGLE_AUTH, LOG_OUT } from "../../common/constants/routePath";

export const googleAuth = async (googleData) => {
  try {
    const response = await axios.get(GOOGLE_AUTH, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${googleData.credential}`,
      },
    });

    // response.data.response = await Decrypt(response.data.response)
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    return [];
  }
};


export const logout = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("email_template_generator"));
    const response = await axios.get(LOG_OUT, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error during logout:", error);
    return [];
  }
};
