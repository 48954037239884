import React from 'react';
import Editor from '@monaco-editor/react';

const HtmlTab = ({ htmlCode, handleHtmlChange, handleEditorDidMount }) => (
  <Editor
    height="70vh"
    width="80vh"
    language="html"
    value={htmlCode}
    onChange={handleHtmlChange}
    options={{
      selectOnLineNumbers: true,
      automaticLayout: true,
      autoClosingBrackets: 'always',
    }}
    onMount={handleEditorDidMount}
  />
);

export default HtmlTab;
