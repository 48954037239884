import React, { useContext } from 'react';
import { HeaderNav } from '../Navbar/Header';
import PreviewTemplate from '../PreviewTemplate/PreviewTemplate';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/joy';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { SessionExpiredContext } from '../../provider/User/SessionExpiredContext';
import SessionExpired from './SessionExpired';

const BackButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '55px',
});

const HeaderContainer = styled('div')({
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 1000,
  backgroundColor: 'white', 
});

const MainContent = styled('main')({
  paddingLeft: '20px',
  paddingRight: '20px',
});

function PreviewPage(user) {
  const navigate = useNavigate();
  const { sessionExpired } = useContext(SessionExpiredContext);
  return (
    <div className='preview-app'>
      <HeaderContainer>
        <HeaderNav user={user} />
      </HeaderContainer>
      <BackButtonContainer>
        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon /> back
        </Button>
      </BackButtonContainer>
      <MainContent>
        <PreviewTemplate />
      </MainContent>
      {sessionExpired && <SessionExpired />}
    </div>
  );
}

export default PreviewPage;
