import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useUser } from './provider/User/user';
import DashBoard from './components/pages/DashBoard';
import LoginPage from './components/pages/LoginPage';
import CreateTemplatePage from './components/pages/CreateTemplatePage';
import PreviewPage from './components/pages/PreviewPage';

const App = () => {
  const client_id= process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const { user } = useUser();
  return (
    <Router>
    <Routes>
        <Route path="/login" element={<LoginPage client_id={client_id} />} />
        <Route path="/createTemplates" element={user ? <CreateTemplatePage user={user} /> : <LoginPage client_id={client_id} />} />
        <Route path="/previewTemplate/:template_id" element={user ? <PreviewPage user={user} /> : <LoginPage client_id={client_id} />} />
        <Route path="/editTemplate/:template_id" element={user ? <CreateTemplatePage user={user} edit={true} /> : <LoginPage client_id={client_id} />} />
        <Route path="/*" element={user ? <DashBoard user={user} /> : <LoginPage client_id={client_id} />} />
    </Routes>
  </Router>
  );
};

export default App;
