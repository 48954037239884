import React from "react";
import { styled } from "@mui/system";
import TemplateCard from "./TemplateCard";

const TemplatesContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "20px",
  width: "100%",
});

const TemplatesList = ({
  templates,
  handleEdit,
  handlePreview,
  copyToClipboard,
  handleMoreClick,
}) => {
  return (
    <TemplatesContainer>
      {templates.map((template, index) => (
        <TemplateCard
          key={index}
          template={template}
          handleEdit={handleEdit}
          handlePreview={handlePreview}
          copyToClipboard={copyToClipboard}
          handleMoreClick={handleMoreClick}
        />
      ))}
    </TemplatesContainer>
  );
};

export default TemplatesList;
