import React, { useContext } from "react";
import { HeaderNav } from "../Navbar/Header";
import ViewTemplate from "../ViewTemplate/ViewTemplate";
import AddIcon from "@mui/icons-material/Add";
// import { Button } from '@mui/joy';
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { SessionExpiredContext } from "../../provider/User/SessionExpiredContext";
import SessionExpired from "./SessionExpired";
import { Button } from "@mui/material";

const BackButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "right",
  marginTop: "60px",
  marginRight: "10px",
});
const HeaderContainer = styled("div")({
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 1000,
  backgroundColor: "white",
});

const MainContent = styled("main")({
  paddingLeft: "20px",
  paddingRight: "20px",
  overflowY:"auto",
});

function DashBoard(user) {
  const navigate = useNavigate();
  const { sessionExpired } = useContext(SessionExpiredContext);
  return (
    <div className="preview-app">
      <HeaderContainer>
        <HeaderNav user={user} />
      </HeaderContainer>
      <BackButtonContainer>
      <Button variant="contained" color="primary" onClick={() => navigate("createTemplates")}>
          <AddIcon/> Create New
        </Button>
      </BackButtonContainer>
      <MainContent>
        <ViewTemplate />
      </MainContent>
      {sessionExpired && <SessionExpired />}
    </div>
    // <div className="App">
    //   <HeaderNav user={user} />
    //   <BackButtonContainer>
    //         <Button variant="contained" color="primary" onClick={() => navigate("createTemplates")}>
    //         <AddIcon/> Create New
    //         </Button>
    //   </BackButtonContainer>
    //  <main>
    //   <ViewTemplate />
    //  </main>
    //  {sessionExpired && <SessionExpired/>}
    // </div>
  );
}

export default DashBoard;
