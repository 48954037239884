import React, { useState } from 'react';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';

const TextInputList = ({ variables, setVariables }) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const validateVariableName = (name) => {
    const trimmedName = name.trim();
    const variableRegex = /^[a-zA-Z][a-zA-Z0-9_]*$/;
    
    if (!variableRegex.test(trimmedName)) {
      return 'Variable names must start with a letter and contain only letters, digits, and underscores.';
    }
    if (variables.includes(trimmedName)) {
      return 'Variable name already exists.';
    }
    return '';
  };

  const handleAddVariable = () => {
    const validationError = validateVariableName(inputValue);
    if (validationError) {
      setError(validationError);
    } else {
      setVariables([...variables, inputValue.trim()]);
      setInputValue('');
      setError('');
    }
  };

  const handleRemoveVariable = (index) => {
    setVariables(variables.filter((_, i) => i !== index));
  };

  return (
    <div className="variable-input-container">
      <p>Add Variables</p>
      <div className='list-class'>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          setError('');
        }}
        placeholder="Enter variable name"
        onKeyPress={(e) => e.key === 'Enter' && handleAddVariable()}
      />
      <button className='close-btn' onClick={handleAddVariable}><PlusOutlined /></button>
      </div>
     
      {error && <p className="error-message">{error}</p>}
      {variables.length > 0 && (
        <div>
          <p>Variables:</p>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {variables.map((variable, index) => (
              <li key={index} className="list-class">
                <span className='list-input'>{variable}</span>
                <button className='close-btn' onClick={() => handleRemoveVariable(index)}> <CloseCircleOutlined /></button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TextInputList;
