import axios from "axios";
import { CREATE_TEMPLATE, DELETE_TEMPLATE, EDIT_TEMPLATE, FETCH_TEMPLATE, REVOKE_ACCESS, SHARE_TEMPLATE, TEMPLATE_USERS, UPDATE_ACCESS, VIEW_TEMPLATES, ZOHO_USERS } from "../../common/constants/routePath";

export const viewTemplates = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("email_template_generator"));
      const response = await axios.get(
        VIEW_TEMPLATES,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch templates", error);
      return error.response.data;
    }
  };

  export const fetchTemplate = async (template_id) => {
    try {
      const user = JSON.parse(localStorage.getItem("email_template_generator"));
      const response = await axios.post(FETCH_TEMPLATE, { template_id },{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch templates", error);
      return error.response.data;
    }
  };
  export const createTemplate = async (parameters, liquid, attachments, templateName, ) => {
    try {
      const user = JSON.parse(localStorage.getItem("email_template_generator"));
      const response = await axios.post(
        CREATE_TEMPLATE,
        { parameters, liquid, attachments, templateName },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      
      return error.response.data;
    }
  };

  export const editTemplate = async (parameters, liquid, attachments, templateName,template_id,deletedAttachments ) => {
    try {
      const user = JSON.parse(localStorage.getItem("email_template_generator"));
      const response = await axios.post(
        EDIT_TEMPLATE,
        { parameters, liquid, attachments, templateName,template_id,deletedAttachments },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      
      return error.response.data;
    }
  };


  export const shareTemplate = async (emailList,templateId,role) => {
    
    try {
      const user = JSON.parse(localStorage.getItem("email_template_generator"));
      // console.log(emailList, templateId, role )
      const response = await axios.post(
        SHARE_TEMPLATE,
        { emailList, templateId, role },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };

  export const fetchTemplateUsers = async (templateId) => {
    try {
      const user = JSON.parse(localStorage.getItem("email_template_generator"));
      const response = await axios.post(
        TEMPLATE_USERS,
        {templateId},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch templates users", error);
      return error.response.data;
    }
  };

  export const fetchUsersEmail =async ()=>{
    try{
      const response= await axios.get(
        ZOHO_USERS
      );
      
      return response.data;
    }catch(error){
      console.error("Failed to fetch users details", error);
      return error.response.data;
    }
    
  }

  export const deleteTemplate = async (templateId) => {
    try {
      const user = JSON.parse(localStorage.getItem("email_template_generator"));
      const response = await axios.post(
        DELETE_TEMPLATE,
        {templateId},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to delete templates", error);
      return error.response.data;
    }
  };

  export const revokeAccess = async (templateId, emailId) => {
    try {
      const user = JSON.parse(localStorage.getItem("email_template_generator"));
      const response = await axios.post(
        REVOKE_ACCESS,
        {templateId, emailId},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to revoke templates", error);
      return error.response.data;
    }
  };

  export const updateAccess = async (templateId, emailId, role) => {
    try {
      const user = JSON.parse(localStorage.getItem("email_template_generator"));
      const response = await axios.post(
        UPDATE_ACCESS,
        {templateId, emailId, role },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to update access", error);
      return error.response.data;
    }
  };