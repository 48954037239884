import React, { useState, useRef, useEffect, useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./HtmlEditor.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HtmlTab from "./HtmlTab";
import CssTab from "./CssTab";
import PreviewTab from "./PreviewTab";
import ToolsPanel from "./ToolsPanel";
import { defaultHtmlCode, defaultCssCode } from "./defaultCode";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import {
  createTemplate,
  editTemplate,
  fetchTemplate,
} from "../../service/Email/EmailTemplate";
import { extractCssAndHtml } from "../../common/utils/extractCssAndHtml";
import {
  SESSION_EXPIRED,
  SOMETHING_WENT_WRONG,
} from "../../common/constants/string";
import SessionExpired from "../pages/SessionExpired";
import { Button, Typography, Box, TextField } from "@mui/material";
import { SessionExpiredContext } from "../../provider/User/SessionExpiredContext";

const HtmlEditor = ({ edit }) => {
  const { template_id } = useParams();
  const [htmlCode, setHtmlCode] = useState(defaultHtmlCode);
  const [templateName, setTemplateName] = useState("Untitled");
  const [cssCode, setCssCode] = useState(defaultCssCode);
  const [variables, setVariables] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [initialUploadedImages, setInitialUploadedImages] = useState([]); // New state to store initial attachments
  const [loading, setLoading] = useState(false);
  const { sessionExpired, setSessionExpired } = useContext(
    SessionExpiredContext
  );
  const [saving, setSaving] = useState(false);
  const monacoRef = useRef(null);
  const disposableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchingTemplate = async () => {
      setLoading(true);
      try {
        const response = await fetchTemplate(template_id);
        if (response.error === SESSION_EXPIRED) {
          toast.error(SESSION_EXPIRED);
          setSessionExpired(true);
        }
        const { templateName, emailContent, parameters, attachments } =
          response.data;
        const { css, html } = extractCssAndHtml(emailContent);
        setTemplateName(templateName);
        setHtmlCode(html);
        setCssCode(css);
        setVariables(parameters);
        setUploadedImages(attachments);
        setInitialUploadedImages(attachments.map(({ name }) => name)); // Store the initial attachment names
      } catch (error) {
        console.error("Error fetching template:", error);
        toast.error("Error fetching template!");
      } finally {
        setLoading(false);
      }
    };

    if (edit) {
      fetchingTemplate();
    }
  }, [edit, template_id, setSessionExpired]);

  const handleHtmlChange = (value) => {
    setHtmlCode(value || "");
  };

  const handleCssChange = (value) => {
    setCssCode(value || "");
  };

  const handleExport = async () => {
    setSaving(true);
    const div = document.createElement("div");
    div.innerHTML = htmlCode.trim();

    uploadedImages.forEach(({ name }) => {
      div.innerHTML = div.innerHTML.replace(
        new RegExp(name, "g"),
        `cid:${name}`
      );
    });

    const parser = new DOMParser();
    const cssDocument = parser.parseFromString(
      `<style>${cssCode}</style>`,
      "text/html"
    );
    const cssRules = Array.from(
      cssDocument.querySelector("style").sheet.cssRules
    );

    const applyInlineStyles = (element) => {
      if (element.nodeType === Node.ELEMENT_NODE) {
        cssRules.forEach((rule) => {
          if (element.matches(rule.selectorText)) {
            Array.from(rule.style).forEach((property) => {
              element.style.setProperty(
                property,
                rule.style.getPropertyValue(property)
              );
            });
          }
        });
      }
      Array.from(element.children).forEach(applyInlineStyles);
    };

    applyInlineStyles(div);

    const inlineHtml = div.innerHTML;
    const variableRegex = /{{(.*?)}}/g;
    const matches = div.innerHTML.match(variableRegex);
    const matchedVariables = matches
      ? matches.map((match) => match.slice(2, -2).trim())
      : [];

    const attachments = uploadedImages.map(({ name, base64Url }) => ({
      name,
      image: base64Url,
    }));

    const deletedAttachments = initialUploadedImages.filter(
      (initialName) =>
        !uploadedImages.some((uploadedImage) => uploadedImage.name === initialName)
    );

    try {
      const response = edit
        ? await editTemplate(
            matchedVariables,
            inlineHtml,
            attachments,
            templateName,
            template_id,
            deletedAttachments // Pass deleted attachment names to the editTemplate function
          )
        : await createTemplate(
            matchedVariables,
            inlineHtml,
            attachments,
            templateName
          );
      setCssCode(defaultCssCode);
      setHtmlCode(defaultHtmlCode);
      if (response.success) {
        toast.success("Template saved successfully!");
        navigate(`previewTemplate/${response.data}`);
      } else if (response.message === SESSION_EXPIRED) {
        toast.error(SESSION_EXPIRED);
        setSessionExpired(true);
      } else {
        toast.error("Something went wrong, template not saved");
      }
    } catch (error) {
      toast.error(SOMETHING_WENT_WRONG);
      console.error("Error sending data to API:", error);
    } finally {
      setSaving(false);
    }
  };

  const registerCompletionProvider = (monaco, variables) => {
    if (disposableRef.current) {
      disposableRef.current.dispose();
    }

    const provider = monaco.languages.registerCompletionItemProvider("html", {
      provideCompletionItems: () => {
        const suggestions = variables.map((variable) => ({
          label: `{{${variable}}}`,
          kind: monaco.languages.CompletionItemKind.Property,
          insertText: `{{${variable}}}`,
        }));
        return { suggestions };
      },
    });

    disposableRef.current = provider;
  };

  const handleEditorDidMount = (editor, monaco) => {
    monacoRef.current = monaco;
    registerCompletionProvider(monaco, variables);
  };

  const handleRemoveImage = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name.replace(/\s+/g, "-"); // Remove spaces from the filename
      const duplicate = uploadedImages.some((image) => image.name === fileName);
      if (duplicate) {
        toast.error(`File name already exists: ${fileName}`);
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          setUploadedImages((prevImages) => [
            ...prevImages,
            { name: fileName, base64Url: e.target.result },
          ]);
        };
        reader.readAsDataURL(file);
      }
    } else {
      toast.error("Unable to read the file. Please try again.");
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Image name copied to clipboard");
      })
      .catch((err) => {
        toast.error("Failed to copy image name");
      });
  };

  useEffect(() => {
    if (monacoRef.current) {
      registerCompletionProvider(monacoRef.current, variables);
    }
  }, [variables]);

  return (
    <div className="html-editor-container">
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography component="label" htmlFor="templateName" mr={2}>
              Template Name:
            </Typography>
            <TextField
              id="templateName"
              variant="outlined"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              size="small"
              style={{ flexGrow: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
              disabled={saving}
              style={{ marginLeft: "20px" }}
            >
              {saving ? (
                <CircularProgress size={24} color="inherit" />
              ) : edit ? (
                "Save Changes"
              ) : (
                "Save Template"
              )}
            </Button>
          </Box>
          <Tabs>
            <TabList>
              <Tab>HTML Editor</Tab>
              <Tab>CSS Editor</Tab>
              <Tab>Preview</Tab>
            </TabList>

            <TabPanel style={{ display: "flex" }}>
              <HtmlTab
                htmlCode={htmlCode}
                handleHtmlChange={handleHtmlChange}
                handleEditorDidMount={handleEditorDidMount}
              />
              <ToolsPanel
                variables={variables}
                setVariables={setVariables}
                uploadedImages={uploadedImages}
                handleImageUpload={handleImageUpload}
                handleRemoveImage={handleRemoveImage}
                copyToClipboard={copyToClipboard}
              />
            </TabPanel>

            <TabPanel>
              <CssTab cssCode={cssCode} handleCssChange={handleCssChange} />
            </TabPanel>

            <TabPanel>
              <PreviewTab
                htmlCode={htmlCode}
                cssCode={cssCode}
                uploadedImages={uploadedImages}
              />
            </TabPanel>
          </Tabs>
        </>
      )}
      {sessionExpired && <SessionExpired />}
    </div>
  );
};

export default HtmlEditor;
