import {
  Avatar,
  Box,
  Dropdown,
  IconButton,
  ListDivider,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/joy";
import React from "react";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import "./Header.css";
import { useUser } from "../../provider/User/user";
import { AccountCircle } from "@mui/icons-material";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { logout } from "../../service/Auth/GoogleAuth";

export const User = () => {
  const { user, setUser } = useUser();

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem("email_template_generator");
      setUser(null);
      navigate("/login");
    } catch (error) {
      // Handle any errors that may occur during the logout process
      console.error(error.message);
    }
  };

  return (
    <Dropdown>
      <MenuButton className="menu-button" variant="plain">
        {user ? (
          <Stack direction="row" spacing={1}>
            <Chip
              avatar={
                <Avatar
                  src={user?.picture}
                  alt="User"
                  className="avatar-size"
                />
              }
              label={user?.name}
              variant="outlined"
            />
          </Stack>
        ) : (
          <AccountCircle fontSize="small" className="avatar-size" />
        )}
      </MenuButton>
      <Menu
        className="menu-open-box"
        placement="bottom-end"
        size="sm"
        sx={{
          zIndex: "100",
          p: 1,
          gap: 1,
          "--ListItem-radius": "var(--joy-radius-sm)",
        }}
      >
        <MenuItem>
          <Box className="user-details-option">
            <IconButton edge="end" color="inherit" className="user-icon">
              {user ? (
                <Avatar
                  src={user.picture}
                  alt="User"
                  className="avatar-circle"
                />
              ) : (
                <AccountCircle fontSize="large" />
              )}
            </IconButton>
            <Box sx={{ ml: 1.5 }}>
              <Typography
                level="title-lg"
                textColor="text.primary"
                sx={{ mt: 1 }}
              >
                {user.name}
              </Typography>
              <Typography level="body-sm" textColor="text.tertiary">
                {user.email}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        <ListDivider />

        <MenuItem onClick={handleLogout} className="logout-option">
          <Box className="options-list">
            <LogoutRoundedIcon />
            <Typography className="options-text">Logout</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};
