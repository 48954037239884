//api endpoints
export const GOOGLE_AUTH = `${process.env.REACT_APP_BASE_URL}google/auth`;
export const CREATE_TEMPLATE = `${process.env.REACT_APP_BASE_URL}create-liquid-templates`;
export const VIEW_TEMPLATES = `${process.env.REACT_APP_BASE_URL}view-templates`;
export const FETCH_TEMPLATE = `${process.env.REACT_APP_BASE_URL}fetch-template`;
export const EDIT_TEMPLATE = `${process.env.REACT_APP_BASE_URL}edit-template`;
export const SHARE_TEMPLATE = `${process.env.REACT_APP_BASE_URL}share-template`;
export const TEMPLATE_USERS = `${process.env.REACT_APP_BASE_URL}template-users`;
export const DELETE_TEMPLATE = `${process.env.REACT_APP_BASE_URL}delete-template`;
export const REVOKE_ACCESS = `${process.env.REACT_APP_BASE_URL}revoke-access`;
export const UPDATE_ACCESS = `${process.env.REACT_APP_BASE_URL}update-access`;
export const GET_EMAIL_CONTENT = `${process.env.REACT_APP_BASE_URL}email-content`;
export const LOG_OUT = `${process.env.REACT_APP_BASE_URL}logout`;
export const ZOHO_USERS = `${process.env.REACT_APP_BASE_URL}zoho-users`;



export const FETCH_ZOHO_USERS = `${process.env.REACT_APP_USER_URL}`;
