import React, { useEffect, useState, useContext } from "react";
import {
  deleteTemplate,
  fetchTemplateUsers,
  viewTemplates,
} from "../../service/Email/EmailTemplate";
import {
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SESSION_EXPIRED } from "../../common/constants/string";
import ShareModal from "./ShareModal";
import { SessionExpiredContext } from "../../provider/User/SessionExpiredContext";
import TemplatesList from "./TemplatesList";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

const Container = styled("div")({
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

const LoadingContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
});

const ViewTemplate = () => {
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const navigate = useNavigate();
  const { setSessionExpired } = useContext(SessionExpiredContext);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [templateUsers, setTemplateUsers] = useState([]);

  useEffect(
    () => {
      const loadTemplates = async () => {
        try {
          const response = await viewTemplates();
          if (response.message === SESSION_EXPIRED) {
            // toast.error(SESSION_EXPIRED);
            setSessionExpired(true);
          }
          const templatesArray = Object.values(response.data);
          setTemplates(templatesArray);
        } catch (error) {
          console.error("Failed to fetch templates:", error);
        } finally {
          setLoading(false);
        }
      };

      if (loading) {
        loadTemplates();
      }
    },
    // eslint-disable-next-line
    [loading]
  );

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleEdit = (template) => {
    navigate(`/editTemplate/${template.templateId}`);
  };

  const handlePreview = (template) => {
    navigate(`/previewTemplate/${template.templateId}`);
    console.log("Preview:", template);
  };

  const handleMoreClick = (event, template) => {
    setAnchorEl(event.currentTarget);
    setSelectedTemplate(template);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTemplate(null);
  };

  const handleDeleteConfirmation = () => {
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteTemplate(selectedTemplate.templateId);

      if (response.message === SESSION_EXPIRED) {
        // toast.error(SESSION_EXPIRED);
        setSessionExpired(true);
      } else if (response.success === false) {
        toast.error(response.message);
      } else {
        toast.success("Template deleted successfully");
        setLoading(true); // Trigger reloading of templates
      }
    } catch (error) {
      console.error("Failed to delete template:", error);
      toast.error("Failed to delete template");
    } finally {
      handleMenuClose();
      handleConfirmDeleteClose();
    }
  };

  const handleShare = async () => {
    const response = await fetchTemplateUsers(selectedTemplate.templateId);
    if (response.message === SESSION_EXPIRED) {
      // toast.error(SESSION_EXPIRED);
      setSessionExpired(true);
    }
    setTemplateUsers(response.data);
    setShareModalOpen(true);
  };

  const closeShareModal = () => {
    setShareModalOpen(false);
    handleMenuClose();
  };

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <CircularProgress color="primary" />
        </LoadingContainer>
      ) : templates && templates.length > 0 ? (
        <>
          <TemplatesList
            templates={templates}
            handleEdit={handleEdit}
            handlePreview={handlePreview}
            copyToClipboard={copyToClipboard}
            handleMoreClick={handleMoreClick}
          />
          {selectedTemplate && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              {selectedTemplate.role === "1" && (
                <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
              )}
              <MenuItem onClick={handleShare}>Share</MenuItem>
            </Menu>
          )}
        </>
      ) : (
        <Typography variant="body1">No templates found.</Typography>
      )}
      {shareModalOpen && selectedTemplate && (
        <ShareModal
          open={shareModalOpen}
          handleClose={closeShareModal}
          template={selectedTemplate}
          templateUsers={templateUsers}
          setTemplateUsers={setTemplateUsers}
        />
      )}
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={handleConfirmDeleteClose}
        onConfirm={handleDelete}
        message={'Are you sure you want to delete this template? This action cannot be undone.'}
      />
    </Container>
  );
};

export default ViewTemplate;
