import React, { useContext, useState } from 'react';
import { HeaderNav } from '../Navbar/Header';
import HtmlEditor from '../Emailtemplate/HtmlEditor';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import ConfirmDeleteDialog from '../ViewTemplate/ConfirmDeleteDialog';
import { Button } from '@mui/joy';
import SessionExpired from './SessionExpired';
import { SessionExpiredContext } from '../../provider/User/SessionExpiredContext';


const BackButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  marginTop:'55px'
});

const HeaderContainer = styled("div")({
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 1000,
  backgroundColor: "white",
});

export const CreateTemplatePage = ({ user, edit }) => {
  const navigate = useNavigate();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { sessionExpired } = useContext(SessionExpiredContext);

  const handleBackButtonClick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmNavigation = () => {
    setOpenConfirmDialog(false);
    navigate(-1);
  };

  return (
    <div className="App">
       <HeaderContainer>
        <HeaderNav user={user} />
      </HeaderContainer>
      <BackButtonContainer>
        <Button variant="contained" color="primary" onClick={handleBackButtonClick}>
          <KeyboardBackspaceIcon /> {edit ? 'Back' : 'Home'}
        </Button>
      </BackButtonContainer>
      <main>
        <HtmlEditor edit={edit} />
      </main>
      <ConfirmDeleteDialog
        open={openConfirmDialog}
        onClose={handleConfirmClose}
        onConfirm={handleConfirmNavigation}
        message="Are you sure you want to leave this page? Any unsaved changes will be lost."
      />
      {sessionExpired && <SessionExpired />}
    </div>
  );
};

export default CreateTemplatePage;
