// src/context/SessionExpiredContext.js
import React, { createContext, useState } from 'react';

export const SessionExpiredContext = createContext();

export const SessionExpiredProvider = ({ children }) => {
  const [sessionExpired, setSessionExpired] = useState(false);

  return (
    <SessionExpiredContext.Provider value={{ sessionExpired, setSessionExpired }}>
      {children}
    </SessionExpiredContext.Provider>
  );
};
