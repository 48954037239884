import React from "react";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../provider/User/user";


const SessionExpired = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();

  const handleLogin = async () => {
    await localStorage.removeItem("email_template_generator");
    setUser(null);
    navigate("/login");
  };

  return (
    <Modal
      open={true}
      sx={{
        backdropFilter: "blur(1px)",
      }}
    >
      <ModalDialog variant="plain" role="alertdialog" size="sm">
        <DialogTitle>
          <WarningRoundedIcon />
          Your session has expired
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>Please login again to continue using the app</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className="primary-theme-bg"
            variant="solid"
            color="neutral"
            onClick={handleLogin}
          >
            Login
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default SessionExpired;
